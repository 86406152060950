<template>
  <NavigationSection />
  <LoaderSection v-show="isLoading" />
  <main ref="root">
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">

              <h1 class="fw-bold h3 text-dark" v-if="transactionItem"><font-awesome-icon :icon="['far', 'fa-compass']" />
                {{ transactionItem.digitalAsset.domain.domainName }}
                <span class="ms-2" role="button" @click="reloadTransaction()" title="Refresh transaction"><font-awesome-icon :icon="['fas', 'fa-rotate']" /></span>
              </h1>

              <div class="clearfix mt-3">

                <nav aria-label="breadcrumb" class="float-none float-lg-start d-block d-lg-inline-block">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{ name: 'adminHome' }" class="text-dark">Efty Pay</router-link></li>
                    <li class="breadcrumb-item"><router-link :to="{ name: 'adminTransactions' }" class="text-dark">Transactions</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      <span v-if="transactionItem">{{ transactionItem.digitalAsset.domain.domainName }}</span>
                      <span v-else-if="transactionError">Not found</span>
                    </li>
                  </ol>
                </nav>

                <div class="float-none float-lg-end d-block d-lg-inline-block">
                  <div class="dropdown">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Manage transaction
                    </button>
                    <ul class="dropdown-menu bg-white">
                      <li>
                        <button class="dropdown-item small" @click="doWhoisLookup()">Run Whois query</button>
                      </li>
                      <li>
                        <button class="dropdown-item small" @click="pushToNextStatus('SELLER')">Push domain to next status (seller)</button>
                      </li>
                      <li>
                        <button class="dropdown-item small" @click="pushToNextStatus('BUYER')">Push domain to next status (buyer)</button>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

              <div v-if="!transactionItem && !transactionError">
                <ContentPlaceholder />
              </div>
              <div v-else-if="transactionError">
                <ErrorMessage />
              </div>
              <div v-else-if="!transactionItem.length">

                <div class="nav-tabs-container position-relative" ref="tabContainer">
                  <ul class="nav nav-tabs mt-4 flex-nowrap" role="tablist">
                    <li class="nav-item">
                      <button class="nav-link bg-white transaction-nav active" id="transaction-tab" data-bs-toggle="tab" data-bs-target="#transaction-details" type="button" role="tab" aria-controls="transaction-details" aria-selected="true"><font-awesome-icon :icon="['far', 'fa-compass']" />Transaction details</button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link bg-white transaction-nav" ref="financialTab" id="financial-tab" data-bs-toggle="tab" data-bs-target="#financial-details" type="button" role="tab" aria-controls="financial-details" aria-selected="true"><font-awesome-icon :icon="['far', 'credit-card']" class="me-2" />Financial</button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link bg-white transaction-nav" ref="whoisTab" id="whois-tab" data-bs-toggle="tab" data-bs-target="#whois" type="button" role="tab" aria-controls="whois" aria-selected="true"><font-awesome-icon :icon="['fas', 'route']" class="me-2" />Whois trail</button>
                    </li>
                    <li class="nav-item" v-if="transactionItem.buyer.id">
                      <button class="nav-link bg-white transaction-nav" ref="messageBuyerTab" id="message-buyer-tab" data-bs-toggle="tab" data-bs-target="#messageBuyer" type="button" role="tab" aria-controls="messageBuyer" aria-selected="true"><font-awesome-icon :icon="['far', 'comments']" class="me-2" />Buyer messages<font-awesome-icon :icon="['fas', 'square']" class="ms-2 text-buyer" /><span class="ms-2" @click="loadMessages()" title="Refresh messages"><font-awesome-icon :icon="['fas', 'fa-rotate']" /></span></button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link bg-white transaction-nav" ref="messageSellerTab" id="message-seller-tab" data-bs-toggle="tab" data-bs-target="#messageSeller" type="button" role="tab" aria-controls="messageSeller" aria-selected="true"><font-awesome-icon :icon="['far', 'comments']" class="me-2" />Seller messages<font-awesome-icon :icon="['fas', 'square']" class="ms-2 text-seller" /><span class="ms-2" @click="loadMessages()" title="Refresh messages"><font-awesome-icon :icon="['fas', 'fa-rotate']" /></span></button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link bg-white transaction-nav" ref="documentsTab" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="documents" aria-selected="true"><font-awesome-icon :icon="['fas', 'download']" class="me-2" />Documents</button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link bg-white transaction-nav" ref="statusBreakdownTab" id="status-breakdown-tab" data-bs-toggle="tab" data-bs-target="#statusBreakdown" type="button" role="tab" aria-controls="statusBreakdown" aria-selected="true"><font-awesome-icon :icon="['fas', 'list-check']" class="me-2" />Status breakdown</button>
                    </li>
                  </ul>
                </div>

                <div class="tab-content">

                  <!-- Transaction details -->
                  <div class="tab-pane fade show active" id="transaction-details" role="tabpanel" aria-labelledby="transaction-details-tab">
                    <div class="card border border-top-0 rounded-0">
                      <div class="card-body bg-white">
                        <div class="row">
                          <div class="col-12 col-lg-8">

                            <div class="row mb-2">
                              <div class="col-4 fw-bold text-muted">
                                Domain
                              </div>
                              <div class="col-8 fw-bold text-success">
                                {{ transactionItem.digitalAsset.domain.domainName }}
                              </div>
                            </div>
                            <div class="row mb-3" v-if="transactionItem.buyer.id">
                              <div class="col-4 fw-bold text-muted">
                                Buyer
                              </div>
                              <div class="col-8">

                                <div v-if="transactionItem.buyer">
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-1 text-buyer" />
                                  {{showFullName(transactionItem.buyer.firstName, transactionItem.buyer.lastName)}}
                                  <span v-if="transactionItem.buyer && transactionItem.buyer.companyAddress && transactionItem.buyer.companyAddress.country != 'COUNTRY_NONE'" class="me-2"><img :src="'https://flagsapi.com/'+transactionItem.buyer.companyAddress.country+'/shiny/16.png'" :title="transactionItem.buyer.companyAddress.country"></span>
                                  <font-awesome-icon class="" :icon="['fas', 'copy']" @click="copyToClipboard(showFullName(transactionItem.buyer.firstName, transactionItem.buyer.lastName))" role="button" title="Copy to clipboard" />
                                </div>

                                <div v-if="transactionItem.buyer.email">
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-white" />
                                  <a :href="'mailto://'+transactionItem.buyer.email">{{ transactionItem.buyer.email }}</a>
                                  <font-awesome-icon class="ms-2" :icon="['fas', 'copy']" @click="copyToClipboard(transactionItem.buyer.email)" role="button" title="Copy to clipboard" />
                                </div>

                                <div v-if="transactionItem.buyer.phoneNumber">
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-white" />
                                  <a :href="'phone://'+transactionItem.buyer.phoneNumber">{{ transactionItem.buyer.phoneNumber }}</a>
                                  <font-awesome-icon class="ms-2" :icon="['fas', 'copy']" @click="copyToClipboard(transactionItem.buyer.phoneNumber)" role="button" title="Copy to clipboard" />
                                </div>

                                <div v-if="transactionItem.buyer.paymentDetails && transactionItem.buyer.paymentDetails.mangopayDetails">
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-white" />
                                  <a target="_blank" :href="'https://dashboard.mangopay.com/User/'+transactionItem.buyer.paymentDetails.mangopayDetails.userId+'/Details'">Mangopay</a>
                                  <font-awesome-icon class="ms-2" :icon="['fas', 'copy']" @click="copyToClipboard(transactionItem.buyer.paymentDetails.mangopayDetails.userId)" role="button" title="Copy to clipboard" />
                                </div>

                              </div>
                            </div>
                            <div class="row mb-4">
                              <div class="col-4 fw-bold text-muted">
                                Seller
                              </div>
                              <div class="col-8">

                                <div v-if="transactionItem.seller">
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-1 text-seller" />
                                  {{showFullName(transactionItem.seller.firstName, transactionItem.seller.lastName)}}
                                  <span v-if="transactionItem.seller && transactionItem.seller.companyAddress && transactionItem.seller.companyAddress.country != 'COUNTRY_NONE'" class="me-2"><img :src="'https://flagsapi.com/'+transactionItem.seller.companyAddress.country+'/shiny/16.png'" :title="transactionItem.seller.companyAddress.country"></span>
                                  <font-awesome-icon class="" :icon="['fas', 'copy']" @click="copyToClipboard(showFullName(transactionItem.seller.firstName, transactionItem.seller.lastName))" role="button" title="Copy to clipboard" />
                                </div>

                                <div v-if="transactionItem.seller.email">
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-white" />
                                  <a :href="'mailto://'+transactionItem.seller.email">{{ transactionItem.seller.email }}</a>
                                  <font-awesome-icon class="ms-2" :icon="['fas', 'copy']" @click="copyToClipboard(transactionItem.seller.email)" role="button" title="Copy to clipboard" />
                                </div>

                                <div v-if="transactionItem.seller.phoneNumber">
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-white" />
                                  <a :href="'phone://'+transactionItem.seller.phoneNumber">{{ transactionItem.seller.phoneNumber }}</a>
                                  <font-awesome-icon class="ms-2" :icon="['fas', 'copy']" @click="copyToClipboard(transactionItem.seller.phoneNumber)" role="button" title="Copy to clipboard" />
                                </div>

                                <div v-if="transactionItem.seller.paymentDetails && transactionItem.seller.paymentDetails.mangopayDetails">
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-white" />
                                  <a target="_blank" :href="'https://dashboard.mangopay.com/User/'+transactionItem.seller.paymentDetails.mangopayDetails.userId+'/Details'">Mangopay</a>
                                  <font-awesome-icon class="ms-2" :icon="['fas', 'copy']" @click="copyToClipboard(transactionItem.seller.paymentDetails.mangopayDetails.userId)" role="button" title="Copy to clipboard" />
                                </div>

                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-4 fw-bold text-muted">
                                Status
                              </div>
                              <div class="col-8">

                                <!-- Different status for buyer and seller, show both -->
                                <div v-if="getTransactionDeepStatusMapping(transactionItem.status, 'buyer').title != getTransactionDeepStatusMapping(transactionItem.status, 'seller').title">
                                  <div>
                                    <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-buyer" /><span class="">{{getTransactionDeepStatusMapping(transactionItem.status, 'buyer').title}}</span>
                                  </div>
                                  <div>
                                    <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-seller" /><span class="">{{getTransactionDeepStatusMapping(transactionItem.status, 'seller').title}}</span>
                                  </div>
                                </div>

                                <!-- Same status for buyer and seller, show one -->
                                <div v-else>
                                  <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-buyer" /><font-awesome-icon :icon="['fas', 'square']" class="me-2 text-seller" /><span class="">{{getTransactionDeepStatusMapping(transactionItem.status, 'buyer').title}}</span>
                                </div>

                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-4 fw-bold text-muted">
                                Creation date
                              </div>
                              <div class="col-8 text-muted">
                                {{dayjs(transactionItem.created).format('ddd MMMM D, YYYY')}}
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-4 fw-bold text-muted">
                                Last update
                              </div>
                              <div class="col-8 text-muted">
                                {{dayjs(transactionItem.updated).format('ddd MMMM D, YYYY')}}
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-4 fw-bold text-muted">
                                Source
                              </div>
                              <div class="col-8 text-muted">
                                <font-awesome-icon :icon="['fas', 'asterisk']" class="me-2" />{{transactionItem.utmParameters.utmSource}} ({{transactionItem.utmParameters.utmContent}})
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-12">
                                <hr>
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-4 fw-bold text-muted">
                                Price <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountChargeVat == true">(ex VAT)</span>
                              </div>
                              <div class="col-8 text-muted">
                                {{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}
                              </div>
                            </div>

                            <div v-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountChargeVat == true">
                              <!-- Only relevant if VAT is charged -->
                              <div class="row mb-2">
                                <div class="col-4 fw-bold text-muted">
                                  VAT
                                </div>
                                <div class="col-8 text-muted">
                                  {{ parseCurrency( transactionItem.currency , transactionItem.vatDetails.vatAmountOnAssetAmount/100) }}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-4 fw-bold text-muted">
                                  Total price (incl VAT)
                                </div>
                                <div class="col-8 text-muted">
                                  {{ parseCurrency( transactionItem.currency , transactionItem.assetAmountIncVat/100) }}
                                </div>
                              </div>
                              <!-- ./Only relevant if VAT is charged -->
                            </div>
                          </div>
                          <div class="col-12 col-lg-4">
                            <h4 class="fw-bold h5 mb-2">Audit trail</h4>
                            <img src="@/assets/404.png" class="img-fluid" v-if="!transactionItem.auditTrail.length">

                            <div id="audit-trail-container">

                              <!-- Interaction widget loaded through dynamic component-->
                              <component :is="interactionComponentInTemplate" class=""/>
                              <!-- ./Interaction widget loaded through dynamic component-->

                              <!-- Audit items -->
                              <div v-for="(auditTrailItem, index) in transactionItem.auditTrail.slice().reverse()" :key="auditTrailItem.id">

                                <div class="w-100 text-center pt-1 audit-arrow position-relative" v-if="index!==0">
                                  <font-awesome-icon class="h4 bg-white" :icon="['fas', 'arrow-circle-up']" />
                                </div>

                                <div class="card p-3 audit-card bg-light" :class="{ 'audit-card-current': index===0, '': index!==0 }">

                                  <div v-if="index===0">
                                    <span class="fw-bold align-middle d-inline-block">{{getHumanReadableTransactionStatus(auditTrailItem.status)}}</span>
                                    <span class="badge bg-success ms-2 border-0 align-middle d-inline-block">Current</span>
                                  </div>
                                  <div v-if="index!==0">
                                    <span class="fw-bold">{{getHumanReadableTransactionStatus(auditTrailItem.status)}}</span>
                                  </div>
                                  <span class="">{{getHumanReadableTransactionSubStatus(auditTrailItem.subStatus)}}</span>
                                  <hr>
                                  <span class="small text-extra-muted m-0">{{ showFullName(auditTrailItem.activityOwner.firstName, auditTrailItem.activityOwner.lastName) }} ({{ auditTrailItem.activityOwner.userType }})</span>
                                  <span class="small text-extra-muted m-0">{{dayjs(auditTrailItem.date).format('MM-DD-YYYY HH:mm')}}</span>

                                </div>

                              </div>
                              <!-- ./Audit items -->

                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                  <!-- ./Transaction details -->



                  <!-- Financial details -->
                  <div class="tab-pane fade" id="financial-details" role="tabpanel" aria-labelledby="financial-details-tab">
                    <div class="card border border-top-0 rounded-0">
                      <div class="card-body bg-white">
                        <div class="row">


                          <!-- Seller overview -->
                          <div class="col-12 col-lg-6">

                            <div class="card border ">
                              <div class="card-body bg-light">
                                <h3 class="fw-bold mb-2">Seller transaction overview</h3>
                                <hr>
                                <!-- Sales price -->
                                <div class="row mb-2">
                                  <div class="col-6">
                                    <p class="mb-1 fw-bold">Domain name {{ transactionItem.digitalAsset.domain.domainName }}</p>
                                  </div>
                                  <div class="col-6 text-muted align-content-end text-end">
                                    {{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}
                                  </div>
                                </div>
                                <!-- ./Sales price -->

                                <!-- VAT on Sales price -->
                                <div class="row mb-2">
                                  <div class="col-6 fw-bold text-muted">

                                    <span class="me-2">VAT</span>

                                    <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountChargeVat">
                                        ({{ transactionItem.vatDetails.vatPercentageOnAssetAmount/100 }}%)
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatOutOfScope">
                                        Out of scope
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatReverseCharge">
                                        Reverse charge
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatOneStopShop">
                                        (OSS / One Stop Shop)
                                      </span>

                                  </div>
                                  <div class="col-6 text-muted text-end">

                                      <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.vatAmountOnAssetAmount > 0">
                                        {{ parseCurrency( transactionItem.currency , transactionItem.vatDetails.vatAmountOnAssetAmount/100) }}
                                      </span>

                                    <span v-else>
                                        -
                                      </span>

                                  </div>
                                </div>
                                <!-- ./VAT on Sales price -->

                                <hr>

                                <!-- Success fee -->
                                <div class="row mb-2" v-if="transactionItem.commissionAmountExcVat">
                                  <div class="col-6 fw-bold text-muted">
                                    Efty Pay Success fee
                                  </div>
                                  <div class="col-6 text-muted text-end">
                                    {{ parseCurrency( transactionItem.currency , transactionItem.commissionAmountExcVat/100) }}
                                  </div>
                                </div>
                                <!-- ./Success fee -->

                                <!-- VAT on Success fee -->
                                <div class="row mb-2">

                                  <div class="col-6 fw-bold text-muted">

                                    <span class="me-2">VAT</span>

                                    <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.commissionAmountChargeVat">
                                        ({{ transactionItem.vatDetails.vatPercentageOnCommissionAmount/100 }}%)
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.commissionAmountVatOutOfScope">
                                        Out of scope
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.commissionAmountVatReverseCharge">
                                        Reverse charge
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.commissionAmountVatOneStopShop">
                                        (OSS / One Stop Shop)
                                      </span>

                                  </div>

                                  <div class="col-6 text-muted text-end">

                                      <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.vatAmountOnCommissionAmount > 0">
                                        {{ parseCurrency( transactionItem.currency , transactionItem.vatDetails.vatAmountOnCommissionAmount/100) }}
                                      </span>

                                    <span v-else>
                                        -
                                      </span>

                                  </div>
                                </div>
                                <!-- ./VAT on Success fee -->

                                <hr>

                                <!-- Total to be received by seller-->
                                <div class="row mb-2">
                                  <div class="col-6 fw-bold text-muted h5">
                                    Total to be received
                                  </div>
                                  <div class="col-6 text-muted h5 fw-bold text-end">
                                    {{ parseCurrency( transactionItem.currency , transactionItem.totalAmountSellerReceives/100) }}
                                  </div>
                                </div>
                                <!-- ./Total to be received by seller -->

                              </div>
                            </div>
                          </div>
                          <!-- Seller overview -->


                          <!-- Buyer overview -->
                          <div class="col-12 col-lg-6">

                            <div class="card border ">
                              <div class="card-body bg-light">
                                <h3 class="fw-bold mb-2">Buyer transaction overview</h3>
                                <hr>
                                <!-- Sales price -->
                                <div class="row mb-2">
                                  <div class="col-6">
                                    <p class="mb-1 fw-bold">Domain name {{ transactionItem.digitalAsset.domain.domainName }}</p>
                                  </div>
                                  <div class="col-6 text-muted align-content-end text-end">
                                    {{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}
                                  </div>
                                </div>
                                <!-- ./Sales price -->

                                <!-- VAT on Sales price -->
                                <div class="row mb-2">
                                  <div class="col-6 fw-bold text-muted">

                                    <span class="me-2">VAT</span>

                                    <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountChargeVat">
                                        ({{ transactionItem.vatDetails.vatPercentageOnAssetAmount/100 }}%)
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatOutOfScope">
                                        Out of scope
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatReverseCharge">
                                        Reverse charge
                                      </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatOneStopShop">
                                        (OSS / One Stop Shop)
                                      </span>

                                  </div>
                                  <div class="col-6 text-muted text-end">

                                      <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.vatAmountOnAssetAmount > 0">
                                        {{ parseCurrency( transactionItem.currency , transactionItem.vatDetails.vatAmountOnAssetAmount/100) }}
                                      </span>

                                    <span v-else>
                                        -
                                      </span>

                                  </div>
                                </div>
                                <!-- ./VAT on Sales price -->

                                <hr>

                                <!-- Express fee -->
                                <div v-if="transactionItem.expressFeeAmountExcVat > 0">

                                  <!-- Express fee amount -->
                                  <div class="row mb-2">
                                    <div class="col-6 fw-bold text-muted">
                                      Express fee (3%)
                                    </div>
                                    <div class="col-6 text-muted text-end">
                                      {{ parseCurrency( transactionItem.currency , transactionItem.expressFeeAmountExcVat/100) }}
                                    </div>
                                  </div>
                                  <!-- ./Express fee amount -->

                                  <!-- VAT on Express fee -->
                                  <div class="row mb-2">

                                    <div class="col-6 fw-bold text-muted">

                                      <span class="me-2">VAT</span>

                                      <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.expressFeeAmountChargeVat">
                                        ({{ transactionItem.vatDetails.vatPercentageOnExpressFeeAmount/100 }}%)
                                      </span>

                                      <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.expressFeeAmountVatOutOfScope">
                                        Out of scope
                                      </span>

                                      <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.expressFeeAmountVatReverseCharge">
                                        Reverse charge
                                      </span>

                                      <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.expressFeeAmountVatOneStopShop">
                                        (OSS / One Stop Shop)
                                      </span>

                                    </div>

                                    <div class="col-6 text-muted text-end">

                                      <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.vatAmountOnExpressFeeAmount > 0">
                                        {{ parseCurrency( transactionItem.currency , transactionItem.vatDetails.vatAmountOnExpressFeeAmount/100) }}
                                      </span>

                                      <span v-else>
                                        -
                                      </span>

                                    </div>

                                  </div>
                                  <!-- ./VAT on Express fee -->

                                  <hr>

                                </div>
                                <!-- Express fee -->


                                <!-- Total to be paid by buyer-->
                                <div class="row mb-2">
                                  <div class="col-6 fw-bold text-muted h5">
                                    Total to be paid
                                  </div>
                                  <div class="col-6 text-muted h5 fw-bold text-end">
                                    {{ parseCurrency( transactionItem.currency , transactionItem.totalAmountToBePaidByBuyer/100) }}
                                  </div>
                                </div>
                                <!-- ./Total to be paid by buyer-->


                              </div>
                            </div>
                          </div>
                          <!-- Seller overview -->



                        </div>

                      </div>
                    </div>
                  </div>
                  <!-- ./Financial details -->



                  <!-- Whois trail -->
                  <div class="tab-pane fade show" id="whois" role="tabpanel" aria-labelledby="whois-tab">
                    <div class="card border border-top-0 rounded-0">
                      <div class="card-body bg-white">

                        <div id="audit-trail-container">
                          <div v-for="(whoisLookupItem, index) in transactionItem.digitalAsset.domain.whoisLookups.slice().reverse()" :key="whoisLookupItem.id">

                            <div class="w-100 text-center pt-1 audit-arrow position-relative" v-if="index!==0">
                              <font-awesome-icon class="h4 bg-white" :icon="['fas', 'arrow-circle-up']" />
                            </div>

                            <div class="card p-3 audit-card" :class="{ 'audit-card-current bg-secondary text-white': index===0, 'bg-light': index!==0 }">

                              <div>
                                <span class="fw-bold align-middle d-inline-block" :class="{ 'text-white': index===0 }">{{whoisLookupItem.registrarName}} (IANAID: {{whoisLookupItem.registrarIANAID}})</span>
                                <span class="badge bg-white text-dark ms-2 border-0 align-middle d-inline-block" v-if="index===0">Latest</span>
                              </div>
                              <div>
                                <span class="fw-bold w-100" :class="{ 'text-white': index===0 }">Owner name: {{whoisLookupItem.ownerName}}</span><br>
                                <span class="fw-bold w-100" :class="{ 'text-white': index===0 }">Owner email: {{whoisLookupItem.ownerEmail}}</span>
                              </div>
                              <div>
                                <span :class="{ 'text-white': index===0 }">DNS: {{whoisLookupItem.dnsNameServerHostNames}}</span>
                              </div>
                              <hr :class="{ 'text-white': index===0 }">
                              <span class="small text-extra-muted m-0" :class="{ 'text-white': index===0 }">Lookup date: {{dayjs(whoisLookupItem.whoisQueryDate).format('MM-DD-YYYY HH:mm')}}</span>
                              <span class="small text-extra-muted m-0" :class="{ 'text-white': index===0 }">Whois updated date: {{dayjs(whoisLookupItem.updatedDate).format('MM-DD-YYYY HH:mm')}}</span>
                              <span class="small text-extra-muted m-0" :class="{ 'text-white': index===0 }">Efty Pay has holding account: {{ whoisLookupItem.eftyPayHasAccountWithRegistrar }}</span>

                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <!-- ./Whois trail -->



                  <!-- Communication / message center for buyer -->
                  <div class="tab-pane fade" id="messageBuyer" role="tabpanel" aria-labelledby="message-buyer-tab">
                    <div class="card border border-top-0 rounded-0 pt-2 bg-white" v-if="transactionItem.buyer">
                      <div class="card-body bg-white text-list-wrapper">
                        <div class="row">

                          <div class="col-12">


                            <!-- Default start message -->
                            <div class="w-75 float-end">
                              <div class="row mt-2">

                                <div class="col-10 offset-2 p-0 pe-4">
                                  <div class="bg-light-grey p-3 rounded-start rounded-bottom bg-message-self text-white">
                                    <span class="fw-bold d-block text-white">Transaction initiated <span v-if="transactionItem.utmParameters" class="text-white">through {{transactionItem.utmParameters.utmSource}}</span></span>
                                    <span class="pt-2 d-block text-white">On {{dayjs(transactionItem.created).format('MM-DD-YYYY')}} this transaction has been created <span v-if="transactionItem.utmParameters" class="text-white">through {{transactionItem.utmParameters.utmSource}}</span></span>
                                    <p v-if="transactionItem.initiatedBy == 'SELLER'">The seller has agreed to Efty Pay B.V.'s Seller's Mandate, Sale and Purchase Agreement and General Terms & Conditions and Privacy Policy.</p>
                                    <p v-else-if="transactionItem.initiatedBy == 'BUYER'">The seller has agreed to Efty Pay B.V.'s Buyer's Mandate, Sale and Purchase Agreement and General Terms & Conditions and Privacy Policy.</p>
                                  </div>

                                  <div class="col-12 text-end">
                                    <p class="small text-extra-muted mt-2 mb-0">{{dayjs(transactionItem.created).format('MM-DD-YYYY HH:mm')}}</p>
                                  </div>

                                </div>

                              </div>
                            </div>
                            <!-- ./Default start message -->

                            <div class="mt-3 w-100 clearfix" v-for="messageItem in transactionMessageList" :key="messageItem.id">

                              <!-- Message where Efty Pay is the author -->
                              <div class="w-75 float-end" v-if="messageItem.author.id == eftyPayAdminId && messageItem.receiver.id == transactionItem.buyer.id">
                                <div class="row">

                                  <div class="col-10 offset-2 p-0 pe-4">
                                    <div class="bg-light-grey p-3 rounded-start rounded-bottom bg-message-self text-white">

                                      <template v-for="(segment, index) in processTextSegments(messageItem.contents)" :key="index">
                                        <span v-if="segment.type === 'text'" class="text-white" v-html="segment.contents"></span>
                                        <span v-else href="#" class="text-white">
                                            {{ segment.contents }} <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(segment.contents)" role="button" title="Copy to clipboard" />
                                          </span>
                                      </template>

                                    </div>

                                    <div class="col-12 text-end">
                                      <p class="small text-extra-muted mt-2 mb-0">{{dayjs(messageItem.date).format('MM-DD-YYYY HH:mm')}}</p>
                                    </div>

                                  </div>

                                </div>
                              </div>

                              <!-- Message where Buyer is the author -->
                              <div class="w-75 float-start" v-else-if="messageItem.author.id == transactionItem.buyer.id && messageItem.receiver.id == eftyPayAdminId">
                                <div class="row">

                                  <div class="col-2 text-center align-top">
                                    <span class="bg-buyer text-white rounded px-2 py-1 lead">{{showInitials(transactionItem.buyer.firstName, transactionItem.buyer.lastName).toUpperCase()}}</span>
                                  </div>

                                  <div class="col-10 p-0 pe-2">
                                    <div class="bg-message-other text-white p-3 rounded-end rounded-bottom">

                                      <template v-for="(segment, index) in processTextSegments(messageItem.contents)" :key="index">
                                        <span v-if="segment.type === 'text'" class="text-white" v-html="segment.contents"></span>
                                        <span v-else href="#" class="text-white">
                                            {{ segment.contents }} <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(segment.contents)" role="button" title="Copy to clipboard" />
                                          </span>
                                      </template>

                                    </div>

                                    <div class="col-12 text-end">
                                      <p class="small text-extra-muted mt-2 mb-0">{{dayjs(messageItem.date).format('MM-DD-YYYY HH:mm')}}</p>
                                    </div>

                                  </div>

                                </div>
                              </div>

                            </div>


                            <!-- Create message -->
                            <hr class="my-3">

                            <div class="row w-100">

                              <div class="col-12 my-auto">

                                <form method="get" action="" id="messageFormBuyer" autocomplete="off">

                                  <div class="position-relative">
                                    <textarea
                                        ref="messageInputBuyer"
                                        v-model="messageBuyer"
                                        @input="resizeTextareaBuyer"
                                        @keydown="handleKeydownBuyer"
                                        name="messageInputBuyer"
                                        id="messageInputBuyer"
                                        class="form-control bg-white overflow-hidden py-3 ps-3"
                                        placeholder="Send a message to the Buyer"
                                        aria-label=""
                                        required
                                        rows="1"
                                    ></textarea>
                                      <button
                                          class="btn position-absolute bottom-0 end-0 mb-1"
                                          @click="addMessageBuyer"
                                          type="button"
                                      >
                                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']" class="text-success fs-2 fw-bold"/>
                                      </button>
                                  </div>

                                </form>

                              </div>
                            </div>
                            <!-- ./Create message -->


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ./Communication / message center for buyer -->


                  <!-- Communication / message center for seller -->
                  <div class="tab-pane fade" id="messageSeller" role="tabpanel" aria-labelledby="message-seller-tab">
                    <div class="card border border-top-0 rounded-0 pt-2 bg-white" v-if="transactionItem.seller">
                      <div class="card-body bg-white text-list-wrapper">
                        <div class="row">

                          <div class="col-12">


                            <!-- Default start message -->
                            <div class="w-75 float-end">
                              <div class="row mt-2">

                                <div class="col-10 offset-2 p-0 pe-4">
                                  <div class="bg-light-grey p-3 rounded-start rounded-bottom bg-message-self text-white">
                                    <span class="fw-bold d-block text-white">Transaction initiated <span v-if="transactionItem.utmParameters" class="text-white">through {{transactionItem.utmParameters.utmSource}}</span></span>
                                    <span class="pt-2 d-block text-white">On {{dayjs(transactionItem.created).format('MM-DD-YYYY')}} this transaction has been created <span v-if="transactionItem.utmParameters" class="text-white">through {{transactionItem.utmParameters.utmSource}}</span></span>
                                    <p v-if="transactionItem.initiatedBy == 'SELLER'">The seller has agreed to Efty Pay B.V.'s Seller's Mandate, Sale and Purchase Agreement and General Terms & Conditions and Privacy Policy.</p>
                                    <p v-else-if="transactionItem.initiatedBy == 'BUYER'">The seller has agreed to Efty Pay B.V.'s Buyer's Mandate, Sale and Purchase Agreement and General Terms & Conditions and Privacy Policy.</p>
                                  </div>

                                  <div class="col-12 text-end">
                                    <p class="small text-extra-muted mt-2 mb-0">{{dayjs(transactionItem.created).format('MM-DD-YYYY HH:mm')}}</p>
                                  </div>

                                </div>

                              </div>
                            </div>
                            <!-- ./Default start message -->

                            <div class="mt-3 w-100 clearfix" v-for="messageItem in transactionMessageList" :key="messageItem.id">

                              <!-- Message where Efty Pay is the author -->
                              <div class="w-75 float-end" v-if="messageItem.author.id == eftyPayAdminId && messageItem.receiver.id == transactionItem.seller.id">
                                <div class="row">

                                  <div class="col-10 offset-2 p-0 pe-4">
                                    <div class="bg-light-grey p-3 rounded-start rounded-bottom bg-message-self text-white">

                                      <template v-for="(segment, index) in processTextSegments(messageItem.contents)" :key="index">
                                        <span v-if="segment.type === 'text'" class="text-white" v-html="segment.contents"></span>
                                        <span v-else href="#" class="text-white">
                                            {{ segment.contents }} <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(segment.contents)" role="button" title="Copy to clipboard" />
                                          </span>
                                      </template>

                                    </div>

                                    <div class="col-12 text-end">
                                      <p class="small text-extra-muted mt-2 mb-0">{{dayjs(messageItem.date).format('MM-DD-YYYY HH:mm')}}</p>
                                    </div>

                                  </div>

                                </div>
                              </div>

                              <!-- Message where Seller is the author -->
                              <div class="w-75 float-start" v-else-if="messageItem.author.id == transactionItem.seller.id && messageItem.receiver.id == eftyPayAdminId">
                                <div class="row">

                                  <div class="col-2 text-center align-top pt-1">
                                    <span class="bg-seller text-white rounded px-2 py-1 lead" data-bs-toggle="tooltip">{{showInitials(transactionItem.seller.firstName, transactionItem.seller.lastName).toUpperCase()}}</span>
                                  </div>

                                  <div class="col-10 p-0 pe-2">
                                    <div class="bg-message-other text-white p-3 rounded-end rounded-bottom">

                                      <template v-for="(segment, index) in processTextSegments(messageItem.contents)" :key="index">
                                        <span v-if="segment.type === 'text'" class="text-white" v-html="segment.contents"></span>
                                        <span v-else href="#" class="text-white">
                                            {{ segment.contents }} <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(segment.contents)" role="button" title="Copy to clipboard" />
                                          </span>
                                      </template>

                                    </div>

                                    <div class="col-12 text-end">
                                      <p class="small text-extra-muted mt-2 mb-0">{{dayjs(messageItem.date).format('MM-DD-YYYY HH:mm')}}</p>
                                    </div>

                                  </div>

                                </div>
                              </div>

                            </div>


                            <!-- Create message -->
                            <hr class="my-3 w-100">

                            <div class="row">

                              <div class="col-12 my-auto">

                                <form method="get" action="" id="messageFormSeller" autocomplete="off">

                                  <div class="position-relative">
                                    <textarea
                                        ref="messageInputSeller"
                                        v-model="messageSeller"
                                        @input="resizeTextareaSeller"
                                        @keydown="handleKeydownSeller"
                                        name="messageInputSeller"
                                        id="messageInputSeller"
                                        class="form-control bg-white overflow-hidden py-3 ps-3"
                                        placeholder="Send a message to the Seller"
                                        aria-label=""
                                        required
                                        rows="1"
                                    ></textarea>
                                    <button
                                        class="btn position-absolute bottom-0 end-0 mb-1"
                                        @click="addMessageSeller"
                                        type="button"
                                    >
                                      <font-awesome-icon :icon="['fas', 'circle-chevron-right']" class="text-success fs-2 fw-bold"/>
                                    </button>
                                  </div>
                                </form>

                              </div>
                            </div>
                            <!-- ./Create message -->


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ./Communication / message center for seller -->



                  <!-- Documents -->
                  <div class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">
                    <div class="card border border-top-0 rounded-0">
                      <div class="card-body bg-white">
                        <div class="row">
                          <div class="col-12">

                            <!-- Seller invoices -->
                            <h4 class="fw-bold ms-3 text-dark">Seller documents</h4>
                            <div  v-if="!invoiceSellerList || invoiceSellerList.length === 0">
                              <div class="alert alert-info" role="alert">
                                <div class="text-dark my-auto">
                                  <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="h4 m-0 me-2"/>
                                  <span class="">Once the payment for the domain is approved, you will be able to download all invoices here.</span>
                                </div>
                              </div>
                            </div>
                            <div v-else-if="invoiceSellerList">
                              <div class="container mt-4">
                                <div class="table-responsive">
                                  <table class="table table-light border table-hover">
                                    <thead>
                                    <tr>
                                      <th scope="col" class="bg-dark-subtle p-3">Invoice#</th>
                                      <th scope="col" class="bg-dark-subtle p-3">Type</th>
                                      <th scope="col" class="bg-dark-subtle p-3">Date</th>
                                      <th scope="col" class="bg-dark-subtle p-3">Total Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="invoice in invoiceSellerList" :key="invoice.id">
                                      <td class="bg-white align-middle p-3">
                                        <router-link :to="{ name: 'adminInvoice', params: { invoiceId: invoice.id, userId: transactionItem.seller.id, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                          {{ invoice.invoiceNumber }}
                                        </router-link>
                                      </td>
                                      <td class="bg-white align-middle p-3">
                                        <router-link :to="{ name: 'adminInvoice', params: { invoiceId: invoice.id, userId: transactionItem.seller.id, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                          <span v-if="invoice.type == 'BUYER_TRANSACTION_OVERVIEW' || invoice.type == 'SELLER_TRANSACTION_OVERVIEW'">Seller transaction overview</span>
                                          <span v-else-if="invoice.type == 'SUCCESS_FEE_INVOICE'">Invoice (Success fee)</span>
                                          <span v-else-if="invoice.type == 'EXPRESS_FEE_INVOICE'">Invoice (Express fee)</span>
                                          <span v-else-if="invoice.type == 'BUYER_PAYMENT_RECEIPT'">Payment receipt</span>
                                        </router-link>
                                      </td>
                                      <td class="bg-white align-middle p-3">
                                        <router-link :to="{ name: 'adminInvoice', params: { invoiceId: invoice.id, userId: transactionItem.seller.id, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                          {{ parseLocalDate(invoice.created) }}
                                        </router-link>
                                      </td>
                                      <td class="bg-white align-middle p-3">
                                        <router-link :to="{ name: 'adminInvoice', params: { invoiceId: invoice.id, userId: transactionItem.seller.id, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                          {{ parseCurrency(invoice.lineItems[0].currency, invoice.totalAmount/100) }}
                                        </router-link>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>

                                </div>
                              </div>
                            </div>
                            <!-- ./Seller invoices -->


                            <!-- Buyer invoices -->
                            <hr>
                            <h4 class="fw-bold ms-3 text-dark mt-3">Buyer documents</h4>
                            <div  v-if="!invoiceBuyerList || invoiceBuyerList.length === 0">
                              <div class="alert alert-info" role="alert">
                                <div class="text-dark my-auto">
                                  <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="h4 m-0 me-2"/>
                                  <span class="">Once the payment for the domain is approved, you will be able to download all invoices here.</span>
                                </div>
                              </div>
                            </div>
                            <div v-else-if="invoiceBuyerList">
                              <div class="container mt-4">
                                <div class="table-responsive">
                                  <table class="table table-light border table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col" class="bg-dark-subtle p-3">Invoice#</th>
                                        <th scope="col" class="bg-dark-subtle p-3">Type</th>
                                        <th scope="col" class="bg-dark-subtle p-3">Date</th>
                                        <th scope="col" class="bg-dark-subtle p-3">Total Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="invoice in invoiceBuyerList" :key="invoice.id">
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'adminInvoice', params: { invoiceId: invoice.id, userId: transactionItem.buyer.id, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                            {{ invoice.invoiceNumber }}
                                          </router-link>
                                        </td>
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'adminInvoice', params: { invoiceId: invoice.id, userId: transactionItem.buyer.id, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                            <span v-if="invoice.type == 'BUYER_TRANSACTION_OVERVIEW' || invoice.type == 'SELLER_TRANSACTION_OVERVIEW'">Buyer transaction overview</span>
                                            <span v-else-if="invoice.type == 'SUCCESS_FEE_INVOICE'">Invoice (Success fee)</span>
                                            <span v-else-if="invoice.type == 'EXPRESS_FEE_INVOICE'">Invoice (Express fee)</span>
                                            <span v-else-if="invoice.type == 'BUYER_PAYMENT_RECEIPT'">Payment receipt</span>
                                          </router-link>
                                        </td>
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'adminInvoice', params: { invoiceId: invoice.id, userId: transactionItem.buyer.id, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                            {{ parseLocalDate(invoice.created) }}
                                          </router-link>
                                        </td>
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'adminInvoice', params: { invoiceId: invoice.id, userId: transactionItem.buyer.id, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                            {{ parseCurrency(invoice.lineItems[0].currency, invoice.totalAmount/100) }}
                                          </router-link>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div class="p-3 my-5 bg-light text-center w-75 mx-auto" role="alert">
                                    <p class="m-0 small text-grey">Please be aware that Efty Pay provides invoices and transaction overviews as an additional service for your convenience. It is important to note that these documents are not substitutes for official tax documents. Buyers and sellers are solely responsible for complying with their local tax laws and regulations. This includes the calculation, reporting, and payment of any applicable taxes arising from transactions conducted through Efty Pay. Efty Pay does not provide tax advice and does not accept responsibility for any tax-related obligations.</p>
                                  </div>

                                </div>
                              </div>
                            </div>


                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                  <!-- ./Documents -->


                  <!-- Status breakdown -->
                  <div class="tab-pane fade" id="statusBreakdown" role="tabpanel" aria-labelledby="status-breakdown-tab">
                    <div class="card border border-top-0 rounded-0 pt-2 bg-white" v-if="transactionItem.status">
                      <div class="card-body bg-white">
                        <div class="row">

                          <div class="col-12">

                            <div class="list-group border-dark">
                              <div v-for="(value, key) in transactionItem.status" :key="key" class="list-group-item bg-white">
                                <div class="row">
                                  <div class="col-4 fw-bold">{{ key }}</div>
                                  <div class="col-8">{{ value }}</div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Status breakdown -->




                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
    <StatusChangeModalSection />
  </main>
</template>

<script>
import ContentPlaceholder from '@/components/admin/ContentPlaceholder.vue'
import FooterSection from '@/components/admin/Footer.vue'
import NavigationSection from '@/components/admin/Navigation.vue'
import StatusChangeModalSection from '@/components/admin/StatusChangeModalSection.vue'
import ErrorMessage from '@/components/admin/ErrorMessage.vue'
import {showInitials, showFullName, parseCurrency, parseLocalDate} from "@/utils/support";
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import {ref, watch, onMounted} from 'vue'
import {
  getTransactionItem,
  deleteTransaction,
  addTransactionMessageItem,
  getTransactionMessageList,
  getNextPossibleTransactionStatus,
  changeTransactionStatus,
  runWhoisLookup,
  transactionItem,
  transactionStatusList,
  transactionError,
  transactionMessageList,
  nextPossibleTransactionStatus,
  nextPossibleTransactionSubStatus
} from "@/utils/transactions.admin";
import {
  getNextTransactionStatus,
  getTransactionDeepStatusMapping,
    getRegistrarStatus,
  getHumanReadableTransactionStatus,
    getHumanReadableTransactionSubStatus
} from "@/utils/transactions.common";
import {
  getInvoiceSellerList,
  invoiceSellerList,
  invoiceError,
  getInvoiceBuyerList,
    invoiceBuyerList
} from "@/utils/invoices.admin";
import {
  eftyPayAdminId,
  eftyIntegratorId
} from "@/utils/config";
import { Tooltip } from 'bootstrap'
import LoaderSection from "@/components/public/LoadingSpinner.vue";
import Swal from "sweetalert2";
import { isLoading } from "@/utils/support";
import {
  getDeepInteractionWidgetComponent,interactionComponentInTemplate
} from "@/utils/interactionWidget";

export default {

  inheritAttrs: true,

  setup() {

    onMounted(() => {

      new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
      })

    });

    // Use router to access the transaction ID
    const route = useRoute();
    const root = ref(null)

    const messageBuyer = ref('');
    const messageInputBuyer = ref(null);
    const messageSeller = ref('');
    const messageInputSeller = ref(null);

    const transactionDocumentList = ''
    const messageBuyerTab = ref(null)
    const sellerBuyerTab = ref(null)

    const reloadTransaction = () => {

      // Load transaction item (prop transactionId is passed by the router)
      getTransactionItem( route.params.transactionId, eftyIntegratorId).then( () => {
        getTransactionMessageList(route.params.transactionId, eftyIntegratorId)
      })

    }

    // Load transaction item (prop transactionId is passed by the router)
    getTransactionItem( route.params.transactionId, eftyIntegratorId)

    // Get next possible status for transaction based on Efty Pay workflow
    getNextPossibleTransactionStatus( route.params.transactionId, eftyIntegratorId )

    const loadMessages = ( transactionId = route.params.transactionId, integratorId = eftyIntegratorId ) => {

      // Load messages
      getTransactionMessageList(transactionId, integratorId)

    }

    watch(transactionItem, () => {
      if (!transactionItem.value) return;

      // Get buyer/seller invoice list
      if (transactionItem.value.buyer) {
        getInvoiceBuyerList(transactionItem.value.buyer.id, transactionItem.value.id)
      } else {
        transactionItem.value.buyer = {}
      }
      getInvoiceSellerList( transactionItem.value.seller.id, transactionItem.value.id)

      const registrarStatus = getRegistrarStatus(transactionItem.value)
      interactionComponentInTemplate.value = false

      getDeepInteractionWidgetComponent(transactionItem.value.status, 'admin', registrarStatus).then( ( interactionComponent ) => {

        if (interactionComponent && interactionComponent.interactionComponent) {
          import(`@/components/admin/interaction/${interactionComponent.interactionComponent}`).then(val => {

            // Get interaction widget, if any
            interactionComponentInTemplate.value = val.default

          })
        }

      })

    });

    const doWhoisLookup = ( ) => {

      runWhoisLookup(transactionItem.value.id, eftyIntegratorId).then( () => {

        // Reload transaction
        getTransactionItem(transactionItem.value.id, eftyIntegratorId)

        Swal.fire({
          toast: true,
          position: 'bottom-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: 'success',
          title: 'Whois request has been processed!'
        })

      })

    }

    const addMessageBuyer = (  ) => {

      // Do HTML validation
      if (! root.value.querySelector('form#messageFormBuyer')[0].checkValidity()) {

        root.value.querySelector('form#messageFormBuyer')[0].reportValidity()

      }
      // Perform API call
      else {

        // Ensure line breaks are preserved
        const formattedMessage = messageBuyer.value.replace(/\n/g, '<br>');

        if (transactionItem.value.buyer && transactionItem.value.buyer.id) {
          // Add message
          addTransactionMessageItem(transactionItem.value.id, eftyPayAdminId, transactionItem.value.buyer.id, formattedMessage, eftyIntegratorId).then(() => {

            // Clear input field
            messageBuyer.value = ''

            // Reset the height of the textarea
            const textarea = messageInputBuyer.value;
            textarea.style.height = 'auto';

          })
        }

      }
    }

    const addMessageSeller = (  ) => {

      // Do HTML validation
      if (! root.value.querySelector('form#messageFormSeller')[0].checkValidity()) {

        root.value.querySelector('form#messageFormSeller')[0].reportValidity()

      }
      // Perform API call
      else {

        // Ensure line breaks are preserved
        const formattedMessage = messageSeller.value.replace(/\n/g, '<br>');

        // Add message
        addTransactionMessageItem(transactionItem.value.id, eftyPayAdminId, transactionItem.value.seller.id, formattedMessage, eftyIntegratorId).then( () => {

          // Clear input field
          messageSeller.value = ''

          // Reset the height of the textarea
          const textarea = messageInputSeller.value;
          textarea.style.height = 'auto';

        })


      }
    }

    const resizeTextareaBuyer = () => {
      const textarea = messageInputBuyer.value;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    const resizeTextareaSeller = () => {
      const textarea = messageInputSeller.value;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    const handleKeydownSeller = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevents the default behavior of Enter key
        addMessageSeller(); // Calls the function to submit the message
      }
    };

    const handleKeydownBuyer = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevents the default behavior of Enter key
        addMessageBuyer(); // Calls the function to submit the message
      }
    };

    const processTextSegments = ( message ) => {

      // Split the text into segments
      const segments = [];
      const regex = /%copy%(.*?)%copy%/g;
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(message)) !== null) {
        // Add the text before the match
        if (match.index > lastIndex) {
          segments.push({ type: 'text', contents: message.substring(lastIndex, match.index) });
        }
        // Add the matched text
        segments.push({ type: 'copy', contents: match[1] });
        lastIndex = regex.lastIndex;
      }
      // Add any remaining text after the last match
      if (lastIndex < message.length) {
        segments.push({ type: 'text', contents: message.substring(lastIndex) });
      }

      return segments;

    }

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        Swal.fire({
          customClass: {
            closeButton: 'btn btn-lg btn-secondary',
            confirmButton: 'btn btn-lg btn-success order-last',
            denyButton: 'btn btn-lg btn-secondary',
            cancelButton: 'btn btn-lg btn-secondary'
          },
          toast: true,
          position: 'center',
          showConfirmButton: true,
          timerProgressBar: true,
          icon: 'success',
          text: 'Copied to the clipboard!',
          confirmButtonText: 'OK',
        })
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
      return false
    };

    const pushToNextStatus = async ( context ) => {

      context = (context == 'BUYER' || context == 'SELLER') ? context : null

      if (context) {

        getNextPossibleTransactionStatus(transactionItem.value.id, eftyIntegratorId, context).then( () => {

          Swal.fire({
            customClass: {
              closeButton: 'btn btn-lg btn-secondary',
              confirmButton: 'btn btn-lg btn-success order-last',
              denyButton: 'btn btn-lg btn-secondary',
              cancelButton: 'btn btn-lg btn-secondary'
            },
            toast: false,
            position: 'center',
            showConfirmButton: true,
            showCancelButton: true,
            timerProgressBar: false,
            icon: 'info',
            title: 'Push transaction into next '+context+' status?',
            html: 'The next status for the '+context+' is:<br><br><h3 class="fw-bold mb-0 text-dark">'+getHumanReadableTransactionStatus(nextPossibleTransactionStatus.value) + ' / ' + getHumanReadableTransactionSubStatus(nextPossibleTransactionSubStatus.value) + '</h3><br>Are you sure you want to push? Automated workflows and e-mails will be triggered.',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, please proceed',
          }).then((result) => {

            if (result.value) {

              changeTransactionStatus(transactionItem.value.id, context).then( () => {

                Swal.fire({
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: 'success',
                  title: 'Transaction has been pushed into the next status!'
                })

              })

            }

          })

        })

      }

    }

    loadMessages()

    // expose to template and other options API hooks
    return {
      transactionItem,
      getNextTransactionStatus,
      getTransactionItem,
      changeTransactionStatus,
      showInitials,
      showFullName,
      addMessageBuyer,
      addMessageSeller,
      parseCurrency,
      dayjs,
      deleteTransaction,
      root,
      transactionDocumentList,
      eftyIntegratorId,
      eftyPayAdminId,
      transactionStatusList,
      transactionError,
      transactionMessageList,
      nextPossibleTransactionStatus,
      nextPossibleTransactionSubStatus,
      messageBuyerTab,
      sellerBuyerTab,
      doWhoisLookup,
      loadMessages,
      isLoading,
      processTextSegments,
      copyToClipboard,
      interactionComponentInTemplate,
      getTransactionDeepStatusMapping,
      getHumanReadableTransactionStatus,
      getHumanReadableTransactionSubStatus,
      pushToNextStatus,
      reloadTransaction,
      invoiceSellerList,
      invoiceBuyerList,
      invoiceError,
      parseLocalDate,
      resizeTextareaBuyer,
      resizeTextareaSeller,
      messageSeller,
      messageBuyer,
      messageInputSeller,
      messageInputBuyer,
      handleKeydownSeller,
      handleKeydownBuyer
    }

  },
  name: 'TransactionItemView',
  components: {LoaderSection, ErrorMessage, FooterSection, NavigationSection, ContentPlaceholder, StatusChangeModalSection }
}
</script>