import axios from 'axios'
import { ref, shallowRef } from 'vue'
import {apiEndpoint, thresholdForCreditCardPayments } from "@/utils/config";
import { isLoading } from "@/utils/support"

const transactionItem = shallowRef(0)
const transactionError = ref(0)
const currentCheckoutStep = ref(0)
const userEmailItem  = ref(0)
const userEmailError = ref(0)

function getThresholdForCreditCardPayments(currency) {
    return thresholdForCreditCardPayments[currency] || thresholdForCreditCardPayments['EUR'];
}

// Get current checkout step
const getCurrentCheckoutStep = async ( transactionItem ) => {

    // Reset
    currentCheckoutStep.value = false

    // Pay-in ID already created for wire transfer? Then fwd to final step
    if ( transactionItem.checkoutDetails && transactionItem.checkoutDetails.mangopayDetails && transactionItem.checkoutDetails.mangopayDetails.paymentMethod == 'BANK_WIRE') {
        currentCheckoutStep.value = 3
    }
    // Credit card payment finalized? Then fwd to final step
    else if ( transactionItem.checkoutDetails && transactionItem.checkoutDetails.mangopayDetails && transactionItem.checkoutDetails.mangopayDetails.paymentMethod == 'CARD' && transactionItem.checkoutDetails.mangopayDetails.checkoutStatus == 'PAYMENT_SUCCEEDED' ) {
        currentCheckoutStep.value = 4
    }
    // Paypal payment finalized? Then fwd to final step
    else if ( transactionItem.checkoutDetails && transactionItem.checkoutDetails.mangopayDetails && transactionItem.checkoutDetails.mangopayDetails.paymentMethod == 'PAY_PAL' && transactionItem.checkoutDetails.mangopayDetails.checkoutStatus == 'PAYMENT_SUCCEEDED' ) {
        currentCheckoutStep.value = 4
    }
    // Let's fill out the billing details first
    else {
        currentCheckoutStep.value = 0
    }

}

// Get user by email (public)
const getUserByEmail = async (encryptedData, iv, keyId, email) => {
    // Activate loading spinner
    isLoading.value = true

    const axiosHeaders = {
        'Access-Control-Request-Method': 'GET',
        Origin: 'localhost'
    }

    userEmailItem.value = false
    userEmailError.value = false

    axios.get(`${apiEndpoint.value}payments/public/users/email/${email}/?encryptedLinkRequest.encryptedData=` + encryptedData + '&encryptedLinkRequest.iv=' + iv + '&encryptedLinkRequest.keyId=' + keyId, {
            headers: axiosHeaders
        }
    ).then(response => {
        isLoading.value = false
        userEmailItem.value = response.data
    }).catch(error => {
        isLoading.value = false
        userEmailError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';
        }
    });
}

// Get content for a specific transaction
const getTransactionItemWithEncrypedData = ( encryptedData, iv, keyId ) => {

    // Activate loading spinner
    isLoading.value = true

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }

    transactionItem.value = false
    transactionError.value = false

    axios.get(apiEndpoint.value + 'transactions/public/enc?encryptedData=' + encryptedData + '&iv=' + iv + '&keyId=' + keyId, {
            headers: axiosHeaders
        }
    ).then(response => {

        isLoading.value = false
        transactionItem.value = response.data

    }).catch(error => {

        isLoading.value = false
        transactionError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            //console.log(error)
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

// Create bankwire Pay In for a specific transaction
const updateTransactionWithEncryptedData = async ( transaction, encryptedData, iv, keyId ) => {

    // Activate loading spinner
    isLoading.value = true

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }

    transactionError.value = false
    const encryptedLinkDetails = { encryptedData: encryptedData, iv: iv, keyId: keyId }

    await axios.put(apiEndpoint.value + 'transactions/public/enc', { encryptedLinkDetails: encryptedLinkDetails, transaction: transaction }, {
            headers: axiosHeaders
        }
    ).then(response => {

        isLoading.value = false
        transactionItem.value = response.data

    }).catch(error => {

        isLoading.value = false
        transactionError.value = error.response.data.message ? error.response.data.message : "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            //console.log(error)
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

// Send magic link to buyer
const sendMagicLinkToBuyer = async ( encryptedData, iv, keyId ) => {

    isLoading.value = true

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
    }

    await axios
        .post(apiEndpoint.value + 'transactions/public/magiclink/email/enc', { encryptedData: encryptedData, iv: iv, keyId: keyId  },{
                headers: axiosHeaders
            }
        ).then(response => {

            isLoading.value = false

            if (response) {
                return false
            }

    }).catch(error => {

        isLoading.value = false

        transactionError.value = "There is an error"
        //console.log(error.response)

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

export {
    transactionItem,
    transactionError,
    currentCheckoutStep,
    getUserByEmail,
    userEmailItem,
    userEmailError,
    sendMagicLinkToBuyer,
    getTransactionItemWithEncrypedData,
    getCurrentCheckoutStep,
    updateTransactionWithEncryptedData,
    getThresholdForCreditCardPayments
}