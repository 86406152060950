import axios from 'axios'
import { ref } from 'vue'
import { transactionStatusList, apiEndpoint } from "@/utils/config";
import Swal from 'sweetalert2'
import {
    eftyIntegratorId
} from "@/utils/config";
import { isLoading } from "@/utils/support";

import { useAuth0Store } from '@/stores/auth0'

const transactionList = ref(0)
const transactionItem = ref(0)
const transactionMessageList = ref(0)
const activeFilter = ref('all')
const transactionError = ref(0)
const transactionMessageError = ref(0)
const transactionDocumentList = ref(0)
const transactionDocumentError = ref(0)
const otpUserProfile = ref(0)
const otpUserId = ref(0)
const nextPossibleTransactionStatus = ref(0)
const nextPossibleTransactionSubStatus = ref(0)
const listRequestObject = ref(0)

// Get list with transactions
const getTransactionList = async ( integratorId, searchValue, limit, startAfter, filter ) => {

    // Activate loading spinner
    isLoading.value = true

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    // Reset
    transactionList.value = false
    transactionError.value = false

    // Default listrequest
    listRequestObject.value = { integratorId: integratorId/*, orderByField: "Created", order: 2 */}

    // Process search
    if (searchValue != false) {
        listRequestObject.value.queries = [
            {
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Created',
                        intValue: 0, // Added as the first filter to ensure we first sort on created date.
                        operator: 'COMPARISON_OPERATOR_GTE'
                    },
                    {
                        field: 'DigitalAsset.domain.DomainName',
                        stringValue: searchValue,
                        operator: 'COMPARISON_OPERATOR_GTE'
                    },
                    {
                        field: 'DigitalAsset.domain.DomainName',
                        stringValue: searchValue + '\uf8ff',
                        operator: 'COMPARISON_OPERATOR_LT'
                    }
                ]
            }
        ]

    }

    /*************************************************************************************************************************************
     *
     * Process filters
     * These filters are all reflections of the interaction widgets as configured in ./utils/interactionWidget.js
     *
     ************************************************************************************************************************************/

    if (filter != false) {

        // Transactions with buyer attached
        if (filter === 'withBuyer') {
            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Created',
                        intValue: 0, // Added as the first filter to ensure we first sort on created date.
                        operator: 'COMPARISON_OPERATOR_GTE'
                    },
                    {
                        field: 'BuyerId',
                        stringValue: '', // Checking for Buyer ID larger than an empty string.
                        operator: 'COMPARISON_OPERATOR_NOT_EQUALS'
                    }
                ]
            }]
        }

        // Admin needs to confirm the payment to Efty Pay
        else if (filter === 'actionAdminConfirmPaymentToEftyPay') {
            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_OR',
                fieldConditions: [
                    {
                        field: 'Status.PAYMENT_TO_EFTY_PAY',
                        intValue: '2', // Example value for 'CONFIRMED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]
        }

        // Admin needs to request transfer to Efty Pay
        else if (filter === 'actionAdminRequestTransferToEftyPay') {
            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Status.PAYMENT_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '0', // Example value for 'SUB_STATUS_NONE'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]
        }

        // Admin needs to approve transfer to Efty Pay with or without auth code
        else if (filter === 'actionAdminApproveTransferToEftyPay') {

            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Status.PAYMENT_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '2', // Example value for 'CONFIRMED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]

        }

        // Admin needs to initiate transfer-out to the buyer
        else if (filter === 'actionAdminInitiateTransferToBuyer') {
            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.TRANSFER_ASSET_TO_BUYER',
                        intValue: '0', // Example value for 'SUB_STATUS_NONE'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]
        }

        // Admin needs to send an auth code to the buyer
        else if (filter === 'actionAdminSendAuthCodeToBuyer') {
            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.TRANSFER_ASSET_TO_BUYER',
                        intValue: '1', // Example value for 'INITIATED_OR_REQUESTED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]
        }

        // Admin needs to approve transfer-in from buyer
        else if (filter === 'actionAdminApproveTransferInFromBuyer') {
            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.TRANSFER_ASSET_TO_BUYER ',
                        intValue: '2', // Example value for 'CONFIRMED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]
        }
        // Admin needs to initiate payout to the seller
        else if (filter === 'actionAdminInitiatePayoutToSeller') {

            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.PAYOUT_TO_SELLER',
                        intValue: '0', // Example value for 'SUB_STATUS_NONE'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]

        }

        // Admin needs to confirm payout to seller
        else if (filter === 'actionAdminConfirmPayoutToSeller') {

            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.PAYOUT_TO_SELLER',
                        intValue: '1', // Example value for 'INITIATED_OR_REQUESTED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]
        }

        // Admin needs to approve payout to seller
        else if (filter === 'actionAdminApprovePayoutToSeller') {

            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.PAYOUT_TO_SELLER',
                        intValue: '2', // Example value for 'CONFIRMED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    }
                ]
            }]
        }

        // Admin needs to complete the transaction
        else if (filter === 'actionAdminCompleteTransaction') {

            listRequestObject.value.queries = [{
                conditionOperator: 'CONDITION_OPERATOR_AND',
                fieldConditions: [
                    {
                        field: 'Created',
                        intValue: 0, // Added as the first filter to ensure we first sort on created date.
                        operator: 'COMPARISON_OPERATOR_GTE'
                    },
                    {
                        field: 'Status.TRANSFER_ASSET_TO_EFTY_PAY',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.PAYOUT_TO_SELLER',
                        intValue: '3', // Example value for 'APPROVED'
                        operator: 'COMPARISON_OPERATOR_EQUALS'
                    },
                    {
                        field: 'Status.TRANSACTION_COMPLETE',
                        intValue: '0', // Example value for 'Not approved'
                        operator: 'COMPARISON_OPERATOR_NOT_EQUALS'
                    }
                ]
            }]
        }
    }

    if (limit) {
        listRequestObject.value.limit = limit
    }

    if (startAfter) {
        listRequestObject.value.startAfter = startAfter
    }

    await axios
        .post(apiEndpoint.value + 'transactions/list', { returnSellerData: true, returnBuyerData: true, listRequest: listRequestObject.value  },{
                headers: axiosHeaders
            }
        ).then(response => {


        isLoading.value = false
        transactionList.value = response.data
    }).catch(error => {
        isLoading.value = false
        transactionError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

// Get content for a specific transaction
const getTransactionItem = async ( transactionId, integratorId ) => {

    // Activate loading spinner
    isLoading.value = true

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    transactionItem.value = false

    transactionError.value = false

    await axios.get(apiEndpoint.value + 'transactions/id/' + transactionId + '/?integratorId=' + integratorId, {
                headers: axiosHeaders
            }
        ).then(response => {
        isLoading.value = false
            transactionItem.value = response.data
        }).catch(error => {
        isLoading.value = false
            transactionError.value = "There is an error"

            if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

                // Redirect to 403 page (work around vue router bc this function is called from within the router)
                //window.location.href = '/#/public/403/';

            }

        });


}

// Get content for a specific transaction
const getNextPossibleTransactionStatus = async ( transactionId, integratorId, context ) => {

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    nextPossibleTransactionStatus.value = false
    nextPossibleTransactionSubStatus.value = false

    if ( transactionId && integratorId && context) {

        await axios.get(apiEndpoint.value + 'transactions/status/next/?transactionId.id=' + transactionId + '&transactionId.integratorId=' + integratorId + '&statusContext=' + context, {
                headers: axiosHeaders
            }
        ).then(response => {

            nextPossibleTransactionStatus.value = response.data.status
            nextPossibleTransactionSubStatus.value = response.data.subStatus

        }).catch(error => {

            if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

                // Redirect to 403 page (work around vue router bc this function is called from within the router)
                //window.location.href = '/#/public/403/';

            }

        });
    }

}

// Get content for a specific transaction
const getOtpUserProfile = (  ) => {

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    otpUserProfile.value = false

    axios.get(apiEndpoint.value + 'users/profile', {
            headers: axiosHeaders
        }
    ).then(response => {
        otpUserProfile.value = response.data
    }).catch(error => {

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

// Get content for a specific transaction
const getOtpUserId = (  ) => {

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    otpUserId.value = false

    transactionError.value = false

    axios.get(apiEndpoint.value + 'users/profile', {
            headers: axiosHeaders
        }
    ).then(response => {
        otpUserId.value = response.data.id
    }).catch(error => {

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

// Get list with message for a transaction
const getTransactionMessageList = ( transactionId, integratorId ) => {

    // Activate loading spinner
    isLoading.value = true

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    // Reset
    transactionMessageList.value = false
    transactionMessageError.value = false

    const listRequestObject = { integratorId: integratorId, orderByField: 'Created', order: 'ORDER_DIRECTION_ASC' }

    axios
        .post(apiEndpoint.value + 'messages/list', { transactionId: transactionId, listRequest: listRequestObject  },{
                headers: axiosHeaders
            }
        ).then(response => {
        isLoading.value = false
        transactionMessageList.value = response.data

    }).catch(error => {
        isLoading.value = false

        transactionMessageError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

// Get list with message for a transaction
const getTransactionDocumentList = ( transactionId ) => {

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    // Reset
    transactionDocumentList.value = false
    transactionDocumentError.value = false

    axios
        .post(apiEndpoint.value + 'messages/list', { transactionId: transactionId },{
                headers: axiosHeaders
            }
        ).then(response => {
        transactionDocumentList.value = response.data
    }).catch(error => {
        transactionDocumentError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

// Do new WHOIS lookup
const runWhoisLookup = async ( transactionId, integratorId ) => {

    // Activate loading spinner
    isLoading.value = true

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    await axios
        .post(apiEndpoint.value + 'transactions/whois', { id: transactionId, integratorId: integratorId },{
                headers: axiosHeaders
            }
        ).then( () => {
        isLoading.value = false

    }).catch(error => {
        isLoading.value = false
        transactionMessageError.value = "There is an error"
        if (error) {
            // Todo
        }
    })

}

// Get list with message for a transaction
const addTransactionMessageItem = async ( transactionId, authorId, receiverId, message, integratorId = '1WTManOJBP7EKsy2TElo7I' ) => {

    // Activate loading spinner
    isLoading.value = true

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    await axios
        .post(apiEndpoint.value + 'messages', { transactionId: transactionId, authorId: authorId, receiverId: receiverId, message: message, integratorId: integratorId  },{
                headers: axiosHeaders
            }
        ).then( () => {

        isLoading.value = false

        // Reload transaction and messages to get updated list of messages
        getTransactionMessageList(transactionId, integratorId)


    }).catch(error => {

        isLoading.value = false
        transactionMessageError.value = "There is an error"
        if (error) {
            // Todo
        }

    })

}

// Change the status of a transaction
const changeTransactionStatus = async ( transactionId, statusContext ) => {

    // Activate loading spinner
    isLoading.value = true

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    if (transactionId && statusContext) {

        await axios
            .put(apiEndpoint.value + 'transactions/status', { transactionId: {'integratorId': eftyIntegratorId, 'id': transactionId}, statusContext: statusContext  },{
                    headers: axiosHeaders
                }
            ).then( (response) => {

                transactionItem.value = response.data

                isLoading.value = false

            }).catch(error => {

                isLoading.value = false
                if (error) {
                    // Todo
                }

            })

    }

}

// Delete a transaction
const deleteTransaction = ( transactionId ) => {

    if (transactionId) {

        Swal.fire({
            customClass: {
                closeButton: 'btn btn-lg btn-secondary',
                confirmButton: 'btn btn-lg btn-success order-last',
                denyButton: 'btn btn-lg btn-secondary',
                cancelButton: 'btn btn-lg btn-secondary'
            },
            toast: false,
            position: 'center',
            showConfirmButton: true,
            showCancelButton: true,
            timerProgressBar: false,
            icon: 'warning',
            title: 'Are you sure?',
            text: 'Do you really want to delete this transaction?'
        }).then((result) => { // <--
            if (result.value) { // <-- if confirmed
                axios
                    .get('transaction-item.json')
                    .then(response => {

                        if (response) {
                            // Todo
                        }
                        Swal.fire({
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            icon: 'success',
                            title: 'The transaction has been deleted'
                        })
                    })

                    .catch(error => {
                        if (error) {
                            // Todo
                        }
                    })
            }
        });


    }

}

// Filter the list with transactions on status
const filterTransactions = (transactionStatus) => {

    // Set filter so buttons can be highlighted in front end
    activeFilter.value = transactionStatus

    if (transactionStatus == 'all') {

        getTransactionList( eftyIntegratorId )

    } else {

        getTransactionList( eftyIntegratorId, transactionStatus )

    }

}

export {
    getTransactionItem,
    getTransactionList,
    getNextPossibleTransactionStatus,
    filterTransactions,
    otpUserProfile,
    transactionList,
    transactionItem,
    activeFilter,
    transactionError,
    transactionStatusList,
    deleteTransaction,
    changeTransactionStatus,
    getTransactionMessageList,
    addTransactionMessageItem,
    runWhoisLookup,
    transactionDocumentList,
    transactionMessageList,
    getTransactionDocumentList,
    getOtpUserProfile,
    getOtpUserId,
    otpUserId,
    nextPossibleTransactionStatus,
    nextPossibleTransactionSubStatus
}